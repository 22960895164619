import { createStore } from "vuex";

import auth from "./modules/auth/index.js";
import stream from "./modules/academic/stream.js";
import subjectGroup from "./modules/academic/subjectGroup.js";
import subject from "./modules/academic/subject.js";
import subjectTeacher from "./modules/academic/subjectTeacher.js";
import subjectStudent from "./modules/academic/subjectStudent.js";
import chapter from "./modules/academic/chapter.js";
import chapterProgression from "./modules/academic/chapterProgression.js";

import appeal from "./modules/appeal/appeal.js";

import teacherSubjectBySectionStandard from "./modules/academic/teacherSubjectBySectionStandard.js";

import session from "./modules/Setup/session.js";
import classModule from "./modules/Setup/class.js";
import classroom from "./modules/Setup/classroom.js";
import section from "./modules/Setup/section.js";
import standard from "./modules/Setup/standard.js";
import teacher from "./modules/User/teacher.js";
import student from "./modules/User/student.js";
import studentTrashed from "./modules/User/studentTrashed.js";
import attribute from "./modules/attribute/attribute.js";
import fee from "./modules/due/fee.js";
import chargeable from "./modules/due/chargeable.js";
import chargeableStudent from "./modules/due/chargeableStudent.js";
import bill from "./modules/due/bill.js";
import billShow from "./modules/due/billShow.js";
import invoice from "./modules/due/invoice.js";
import razorpay from "./modules/razorpay/razorpay.js";
import offlinePayment from "./modules/offlinePayment/offlinePayment.js";

import attendance from "./modules/attendance/attendance.js";
import attendanceClass from "./modules/attendance/attendanceClass.js";
import attendanceClassroom from "./modules/attendance/attendanceClassroom.js";

import homework from "./modules/homework/homework.js";

import holidaySMS from "./modules/sms/holidaySMS.js";
import absenteeSMS from "./modules/sms/absenteeSMS.js";
import unpaidDueSMS from "./modules/sms/unpaidDueSMS.js";

import notification from "./modules/notification/notification.js";

import exam from "./modules/exam/exam.js";
import examSchedule from "./modules/exam/examSchedule.js";
import examSectionStandard from "./modules/exam/examSectionStandard.js";
import examSubject from "./modules/exam/examSubject.js";
import examUser from "./modules/exam/examUser.js";
import examQuestion from "./modules/exam/examQuestion.js";
import studentUsersForExamSubject from "./modules/exam/StudentUsersForExamSubject.js";
import examAnswer from "./modules/exam/examAnswer.js";
import examSubjectScore from "./modules/exam/examSubjectScore.js";
import examStandard from "./modules/exam/examStandard.js";

import tinyMce from "./modules/tinyMce/tinyMce.js";

import event from "./modules/calendar/event.js";

const store = createStore({
  modules: {
    auth,
    stream,
    subjectGroup,
    subject,
    examUser,
    subjectTeacher,
    subjectStudent,
    chapter,
    chapterProgression,
    appeal,

    teacherSubjectBySectionStandard,

    session,
    section,
    standard,
    class: classModule,
    classroom,
    teacher,
    student,
    studentTrashed,
    attribute,
    fee,
    chargeable,
    chargeableStudent,
    bill,
    billShow,
    invoice,

    razorpay,
    offlinePayment,

    attendance,
    attendanceClass,
    attendanceClassroom,

    homework,

    exam,
    examSchedule,
    examSectionStandard,
    examSubject,
    examQuestion,
    studentUsersForExamSubject,
    examAnswer,
    examSubjectScore,
    examStandard,

    tinyMce,

    holidaySMS,
    absenteeSMS,
    unpaidDueSMS,
    notification,
    event,
  },

  state() {
    return {
      reload: null,
    };
  },

  getters: {
    reload(state) {
      return state.reload;
    },
  },

  mutations: {
    clearReload(state) {
      state.reload = null;
    },

    setReload(state, payLoad) {
      state.reload = payLoad;
    },
  },

  actions: {
    clearReload(context) {
      context.commit("clearReload");
    },

    setReload(context, payLoad) {
      context.commit("setReload", payLoad);
    },
  },
});

export default store;
