import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`chargeable-student`, {
            params : {
                page: page,
                chargeable_id: id,
            }
        } )
    },

    // async all(){
    //     return Api.get(`chargeable-student-all`)
    // },

    async studentsByStandard(standard_id){
        return Api.get(`student-by-standard/${standard_id}`)
    },

    async create(data){
        return Api.post(`chargeable-student`, data)
    },

    // async update(id, data){
    //     return Api.put(`chargeable-student/${id}`, data)
    // },
    
    async delete(id){
        return Api.delete(`chargeable-student/${id}`)
    }
}