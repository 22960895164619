import Api from '../base/index.js'

export default{
    async index(id, page=1){
        return Api.get(`section-standard/${id}?page=${page}`)
    },

    async create(data){
        return Api.post(`student`, data)
    },

    async updateStudent(id, data){
        return Api.put(`student/${id}`, data)
    },

    async deleteStudent(id){
        return Api.delete(`student/${id}`)
    }
}