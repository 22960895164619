import Api from '../base/index.js'

export default{
    async studentBySectionStandard(){
        return Api.get(`student-by-section-standard`)
    },

    async sendHolidaySMS(data){
        return Api.post(`send-holiday-sms`, data)
    },

    async sendAbsenteeSMS(data){
        return Api.post(`send-absentee-sms`, data)
    },

    async sendUnpaidDueSMS(data){
        return Api.post(`send-unpaid-due-sms`, data)
    },

    async studentBySectionStandardWithAttendance(date){
        return Api.get(`student-by-section-standard-with-attendance?date=${date}`)
    },

    async studentBySectionStandardWithUnpaidDue(){
        return Api.get(`student-by-section-standard-with-unpaid-due`)
    },
}