// import Base from "./base.js";

// let token = localStorage.getItem("token");

// if (token) {
//   Base.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// }

// export default Base;

import axios from "axios";
import base_url from "../base_url.js";

let Base = axios.create({
  baseURL: base_url + "api/v1/",
});

Base.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Base.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.message == "Network Error") {
      document.dispatchEvent(
        new CustomEvent("offline", { detail: error })
      );
    }
    return Promise.reject(error);
  }
);

export default Base;
