import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "../store/index.js";

const routes = [
  {
    path: "",
    redirect: "/dashboard",
  },

  {
    path: "/login",
    component: () => import("../views/Pages/Auth/Login.vue"),
    meta: { requiresUnauth: true },
    name: "login",
  },

  {
    path: "/dashboard",
    component: () => import("../views/Pages/Main/Dashboard.vue"),
    meta: { requiresAuth: true },
    name: "dashboard",
  },

  {
    path: "/profile",
    component: () => import("../views/Pages/Auth/Profile.vue"),
    meta: { requiresAuth: true },
    name: "profile",
  },

  {
    path: "/profile-update",
    component: () => import("../views/Pages/Auth/ProfileUpdate.vue"),
    meta: { requiresAuth: true },
    name: "profile.update",
  },

  {
    path: "/due",
    component: () => import("../views/Pages/Due/Due.vue"),
    meta: { requiresAuth: true },
    name: "due",
  },

  {
    path: "/fee",
    component: () => import("../views/Pages/Due/Fee.vue"),
    meta: { requiresAuth: true },
    name: "fee",
  },

  {
    path: "/chargeable",
    component: () => import("../views/Pages/Due/Chargeable.vue"),
    meta: { requiresAuth: true },
    name: "chargeable",
  },

  {
    path: "/chargeable/chargeable-student/:chargeable/:title",
    component: () => import("../views/Pages/Due/ChargeableStudent.vue"),
    meta: { requiresAuth: true },
    name: "chargeable.student",
    props: true,
  },
  
  {
    path: "/bill",
    component: () => import("../views/Pages/Due/Bill.vue"),
    meta: { requiresAuth: true },
    name: "bill",
  },

  {
    path: "/bill/:bill",
    component: () => import("../views/Pages/Due/BillShow.vue"),
    meta: { requiresAuth: true },
    name: "bill.show",
    props: true,
  },

  {
    path: "/invoice",
    component: () => import("../views/Pages/Due/Invoice.vue"),
    meta: { requiresAuth: true },
    name: "invoice",
    props: true,
  },

  {
    path: "/invoice/:invoice",
    component: () => import("../views/Pages/Due/InvoiceShow.vue"),
    meta: { requiresAuth: true },
    name: "invoice.show",
    props: true,
  },

  {
    path: "/academic",
    component: () => import("../views/Pages/Academic/Academic.vue"),
    meta: { requiresAuth: true },
    name: "academic",
  },

  {
    path: "/academic/stream",
    component: () => import("../views/Pages/Academic/Stream.vue"),
    meta: { requiresAuth: true },
    name: "stream",
  },

  {
    path: "/academic/subject-group",
    component: () => import("../views/Pages/Academic/SubjectGroup.vue"),
    meta: { requiresAuth: true },
    name: "subjectGroup",
  },

  {
    path: "/academic/subject",
    component: () => import("../views/Pages/Academic/Subject.vue"),
    meta: { requiresAuth: true },
    name: "subject",
  },

  {
    path: "/academic/subject-teacher/:subject/:title",
    component: () => import("../views/Pages/Academic/SubjectTeacher.vue"),
    meta: { requiresAuth: true },
    name: "subject.teacher",
    props: true,
  },

  {
    path: "/academic/subject-student/:subject/:title",
    component: () => import("../views/Pages/Academic/SubjectStudent.vue"),
    meta: { requiresAuth: true },
    name: "subject.student",
    props: true,
  },

  {
    path: "/academic/subject-chapter/:subject/:title",
    component: () => import("../views/Pages/Academic/SubjectChapter.vue"),
    meta: { requiresAuth: true },
    name: "subject.chapter",
    props: true,
  },

  {
    path: "/academic/subject-chapter/chapter-progression/:chapter/:description",
    component: () => import("../views/Pages/Academic/ChapterProgression.vue"),
    meta: { requiresAuth: true },
    name: "chapter.progression",
    props: true,
  },

  {
    path: "/setup",
    component: () => import("../views/Pages/Setup/Setup.vue"),
    meta: { requiresAuth: true },
    name: "setup",
  },

  {
    path: "/setup/session",
    component: () => import("../views/Pages/Setup/Session.vue"),
    meta: { requiresAuth: true },
    name: "session",
  },

  {
    path: "/setup/section",
    component: () => import("../views/Pages/Setup/Section.vue"),
    meta: { requiresAuth: true },
    name: "section",
  },

  {
    path: "/setup/standard",
    component: () => import("../views/Pages/Setup/Standard.vue"),
    meta: { requiresAuth: true },
    name: "standard",
  },

  {
    path: "/setup/class",
    component: () => import("../views/Pages/Setup/Class.vue"),
    meta: { requiresAuth: true },
    name: "class",
  },
  
  {
    path: "/setup/class/:class",
    component: () => import("../views/Pages/Setup/Classroom.vue"),
    meta: { requiresAuth: true },
    name: "class.show",
    props: true,
  },

  {
    path: "/setup/transfer-student",
    component: () => import("../views/Pages/Setup/TransferStudent.vue"),
    meta: { requiresAuth: true },
    name: "transfer.student",
  },

  {
    path: "/setup/delist-student",
    component: () => import("../views/Pages/Setup/DelistedStudent.vue"),
    meta: { requiresAuth: true },
    name: "delist.student",
  },

  {
    path: "/user",
    component: () => import("../views/Pages/User/User.vue"),
    meta: { requiresAuth: true },
    name: "user",
  },

  {
    path: "/user/teacher",
    component: () => import("../views/Pages/User/Teacher.vue"),
    meta: { requiresAuth: true },
    name: "teacher",
  },

  {
    path: "/user/student",
    component: () => import("../views/Pages/User/Student.vue"),
    meta: { requiresAuth: true },
    name: "student",
  },

  {
    path: "/payment_successfull/:invoice",
    component: () => import("../views/Pages/Due/PaymentSuccessfull.vue"),
    meta: { requiresAuth: true },
    name: "payment.successfull",
    props: true,
  },

  {
    path: "/attendance",
    component: () => import("../views/Pages/Attendance/Attendance.vue"),
    meta: { requiresAuth: true },
    name: "attendance",
  },

  {
    path: "/attendance/:class",
    component: () => import("../views/Pages/Attendance/AttendanceShow.vue"),
    meta: { requiresAuth: true },
    name: "attendance.show",
    props: true,
  },


  {
    path: "/homework",
    component: () => import("../views/Pages/Homework/Homework.vue"),
    meta: { requiresAuth: true },
    name: "homework",
  },

  {
    path: "/calendar",
    component: () => import("../views/Pages/Calendar/Calendar.vue"),
    meta: { requiresAuth: true },
    name: "calendar",
  },

  {
    path: "/communication",
    component: () => import("../views/Pages/Communication/Communication.vue"),
    meta: { requiresAuth: true },
    name: "communication",
  },

  {
    path: "/sms-template",
    component: () => import("../views/Pages/Communication/TemplateSMS.vue"),
    meta: { requiresAuth: true },
    name: "sms.template",
  },

  {
    path: "/sms-template/due",
    component: () => import("../views/Pages/Communication/SMS/Due.vue"),
    meta: { requiresAuth: true },
    name: "sms.template.due",
  },

  {
    path: "/sms-template/absentee",
    component: () => import("../views/Pages/Communication/SMS/Absentee.vue"),
    meta: { requiresAuth: true },
    name: "sms.template.absentee",
  },

  {
    path: "/sms-template/holiday",
    component: () => import("../views/Pages/Communication/SMS/Holiday.vue"),
    meta: { requiresAuth: true },
    name: "sms.template.holiday",
  },

  {
    path: "/notification",
    component: () => import("../views/Pages/Communication/Notification/Notification.vue"),
    meta: { requiresAuth: true },
    name: "notification",
  },

  {
    path: "/notification-show",
    component: () => import("../views/Pages/Communication/Notification/NotificationShow.vue"),
    meta: { requiresAuth: true },
    name: "notification.show",
  },

  {
    path: "/sms-report",
    component: () => import("../views/Pages/Communication/SMS/Report.vue"),
    meta: { requiresAuth: true },
    name: "sms.report",
  },

  {
    path: "/appeal",
    component: () => import("../views/Pages/Appeal/Appeal.vue"),
    meta: { requiresAuth: true },
    name: "appeal",
  },

  {
    path: "/exam",
    component: () => import("../views/Pages/Exam/Exam.vue"),
    meta: { requiresAuth: true },
    name: "exam",
  },
  {
    path: "/exam/create",
    component: () => import("../views/Pages/Exam/CreateExam.vue"),
    meta: { requiresAuth: true },
    name: "exam.create",
  },

  {
    path: "/exam/list",
    component: () => import("../views/Pages/Exam/ListExam.vue"),
    meta: { requiresAuth: true },
    name: "exam.list",
  },

  {
    path: "/exam/schedule/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamSchedule.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.schedule",
  },

  {
    path: "/exam/section-standard/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamSectionStandard.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.section-standard",
  },

  {
    path: "/exam/subject/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamSubject.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.subject",
  },

  {
    path: "/exam/evaluate/:exam_subject_id/:title/:exam_name",
    component: () => import("../views/Pages/Exam/Evaluate.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.evaluate",
  },

  {
    path: "/exam/student-mark/:exam_subject_id/:title/:exam_name",
    component: () => import("../views/Pages/Exam/StudentMark.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.student-mark",
  },

  {
    path: "/exam/user/:exam/:title",
    component: () => import("../views/Pages/Exam/ExamUser.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.user",
  },

  {
    path: "/exam/question/:exam_subject_id/:title",
    component: () => import("../views/Pages/Exam/ExamQuestion.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.question",
  },

  {
    path: "/exam/control/:exam/:title/:exam_type",
    component: () => import("../views/Pages/Exam/ExamControl.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.control",
  },

  {
    path: "/exam/result/:exam/:title/:exam_type/:exam_name",
    component: () => import("../views/Pages/Exam/Result/ExamStandard.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.result",
  },

  {
    path: "/exam/student-result/:exam/:title/:exam_type/:standard_id",
    component: () => import("../views/Pages/Exam/Result/ExamUser.vue"),
    meta: { requiresAuth: true },
    props: true,
    name: "exam.student-result",
  },

  {
    path: "/logedout",
    component: () => import("../views/Pages/LogedOut.vue"),
    name: "logedout",
  },

  {
    path: "/:notFound(.*)",
    component: () => import("../views/Pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const auth = store.dispatch("auth/tryLogin");

router.beforeEach(async function(to, _, next) {
  try {
    if (to.meta.requiresAuth && !store.getters["auth/isAuthenticated"]) {
      await auth;
      next({name:"login"});
    } else if (
      to.meta.requiresUnauth &&
      store.getters["auth/isAuthenticated"]
    ) {
      next({name:"dashboard"});
    } else {
      next();
    }
  } catch (error) {
    console.warn(error);
  }
});

export default router;
