import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`chargeable?page=${page}`)
    },

    async all(){
        return Api.get(`chargeable-all`)
    },

    async show(id){
        return Api.get(`chargeable/${id}`)
    },

    async create(data){
        return Api.post(`chargeable`, data)
    },

    async update(id, data){
        return Api.put(`chargeable/${id}`, data)
    },

    async delete(id){
        return Api.delete(`chargeable/${id}`)
    }
}