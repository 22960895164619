import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`chapter`, {
            params : {
                page: page,
                subject_id: id,
            }
        } )
    },

    async all(id){
        return Api.get(`chapter-all/${id}`)
    },

    async create(data){
        return Api.post(`chapter`, data)
    },

    async update(id, data){
        return Api.put(`chapter/${id}`, data)
    },
    
    async delete(id){
        return Api.delete(`chapter/${id}`)
    }
}