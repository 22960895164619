import Api from "../base/index.js";

export default {
  async index(page = 1, search_string='') {
    return Api.get(`teacher?page=${page}&search_string=${search_string}`);
  },

  async create(data) {
    return Api.post(`teacher`, data);
  },

  async update(id, data) {
    return Api.put(`user/${id}`, data);
  },
  async delete(id) {
    return Api.delete(`teacher/${id}`);
  },
  async all() {
    return Api.get(`teacher-all`);
  },
};
