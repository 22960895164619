import Api from "../base/index.js";

export default {
  async index(page = 1, search_string = "", filters) {
    return Api.get(`student`, {
      params: {
        page: page,
        search_string: search_string,
        standard_id: filters.standard_id,
        section_id: filters.section_id,
      }
    });
  },

  async unallocated() {
    return Api.get(`unallocated-student`);
  },

  async create(data) {
    return Api.post(`enroll-student`, data);
  },

  async update(id, data) {
    return Api.put(`update-student/${id}`, data);
  },

  async delete(id) {
    return Api.delete(`student/${id}`);
  },

  // async all() {
  //   return Api.get(`student-all`);
  // },

  async studentsBySectionStandard(data) {
    return Api.get(
      `students-by-section-standard?standard_id=${data.standard_id}&section_id=${data.section_id}`
    );
  },

  async transferStudent(data) {
    return Api.put(`transfer-student`, data);
  },
};
