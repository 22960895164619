import Api from '../base/index.js'

export default {
    // async index(page=1, data, segment){
    //     return Api.get(`manual-payment`, {
    //         params : {
    //             page: page,

    //             session_id: data.session_id,
    //             bill_id: data.bill_id,
    //             fee_id: data.fee_id,
    //             standard_id: data.standard_id,
    //             invoice_id: data.invoice_id,
    //             segment: segment,
    //         }
    //     } )
    // },

    async show(id) {
        return Api.get(`manual-payment/${id}`)
    },

    async update(id, data) {
        return Api.put(`manual-payment/${id}`, data)
    },
    // async download(id){
    //     return Api.get(`manual-payment-print/${id}`, {responseType: 'blob'})
    // },
}