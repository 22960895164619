import Api from "../base/index.js";

export default {

  async index(page = 1, search_string='') {
    return Api.get(`student-trashed?page=${page}&search_string=${search_string}`);
  },

  // async unallocated(){
  //   return Api.get(`unallocated-student`);
  // },

  async create(data) {
    return Api.post(`student-trashed`, data);
  },

  async update(id, data) {
    return Api.put(`student-trashed/${id}`, data);
  },

  // async delete(id) {
  //   return Api.delete(`student/${id}`);
  // },

  // async all() {
  //   return Api.get(`student-all`);
  // },

};
