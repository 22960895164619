import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`stream?page=${page}`)
    },

    async all(){
        return Api.get(`stream-all`)
    },

    async create(data){
        return Api.post(`stream`, data)
    },

    async update(id, data){
        return Api.put(`stream/${id}`, data)
    },
    
    async delete(id){
        return Api.delete(`stream/${id}`)
    }
}