import Api from "../base/index.js";

export default {
  async eventTypes() {
    return Api.get(`event-type`);
  },

  async index(page = 1, startOfMonth) {
    return Api.get(`event?page=${page}&start_of_month=${startOfMonth}`);
  },

  //   async all() {
  //     return Api.get(`event-all`);
  //   },

  //   async show(id) {
  //     return Api.get(`event/${id}`);
  //   },

  async update(id, data) {
    return Api.put(`event/${id}`, data);
  },

  async delete(id) {
    return Api.delete(`event/${id}`);
  },

  async create(data) {
    return Api.post(`event`, data);
  },
};
