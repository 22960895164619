import StudentTrashed from "../../../apis/student/studentTrashed.js";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: [],
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      keys: {
        resources: {
          localStorageName: "students_trashed",
        },
      },

      unallocatedResources: null,
    };
  },

  getters: {
    unallocatedResources(state) {
      return state.unallocatedResources;
    },

    resourcesKey(state) {
      return state.keys.resources;
    },

    resources(state) {
      return state.resources;
    },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response.data;
      } else {
        state.resources.data.push(...payLoad.response.data);
      }
      state.resources.current_page = payLoad.response.current_page;
      state.resources.last_page = payLoad.response.last_page;
      state.resources.total = payLoad.response.total;
      localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    },

    unallocatedResources(state, payLoad) {
      state.unallocatedResources = payLoad;
    },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const key = context.getters.resourcesKey;
      const inLocalStorage = refresh
        ? null
        : await JSON.parse(localStorage.getItem(key.localStorageName));

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;
      const search_string = payLoad.search_string === null ? '' : payLoad.search_string;

      if (nextResourcesPage) {
        //  const response = await ImportedApi.index(page)
        const response = await StudentTrashed.index(nextResourcesPage, search_string);

        const dispatch = {
          response: response.data,
          refresh: refresh,
          key: key.localStorageName,
        };
        context.commit("resources", dispatch);
      }
    },

    async createResource(context, payLoad) {
      // const response = await ImportedApi.create(resource_id)
      await StudentTrashed.create(payLoad);
      context.dispatch("resources", { refresh: true });
    },

    async updateResource(context, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad
      await StudentTrashed.update(payLoad.id, data);
      context.dispatch("resources", { refresh: true });
    },
  },
};
