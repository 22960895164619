import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`standard?page=${page}`)
    },

    async all(){
        return Api.get(`standard-all`)
    },

    async create(data){
        return Api.post(`standard`, data)
    },

    async update(id, data){
        return Api.put(`standard/${id}`, data)
    },
    async delete(id){
        return Api.delete(`standard/${id}`)
    }
}