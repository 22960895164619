import Api from '../base/index.js'

export default{
    async index(page=1, data, segment){
        return Api.get(`appeal`, {
            params : {
                page: page,
                
                session_id: data.session_id,
                appeal_type_id: data.appeal_type_id,
                segment: segment,
            }
        } )
    },

    // async show(id){
    //     return Api.get(`appeal/${id}`)
    // },

    // async create(data){
    //     return Api.post(`appeal`, data)
    // },

    // async update(id, data){
    //     return Api.put(`appeal/${id}`, data)
    // },

    async resolve(id, data){
        return Api.put(`appeal-resolve/${id}`, data)
    },

    // async delete(id){
    //     return Api.delete(`appeal/${id}`)
    // }
}