import Auth from "../../../apis/auth/index";
import User from "../../../apis/user/index.js";

export default {
  namespaced: true,

  state() {
    return {
      user: null,
      token: null,
    };
  },

  getters: {
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    isAuthenticated(_, getters) {
      return getters.user !== null ? true : false;
    },
  },

  mutations: {
    login(state, payLoad) {
      state.user = payLoad.user;
      state.token = payLoad.token;
      localStorage.setItem("user", JSON.stringify(payLoad.user));
      localStorage.setItem("token", payLoad.token);
    },
    logout(state) {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
    user(state, payLoad) {
      state.user = payLoad;
      localStorage.setItem("user", JSON.stringify(payLoad));
    },
    token(state, payLoad) {
      state.token = payLoad;
      localStorage.setItem("token", payLoad);
    },
  },

  actions: {
    async tryLogin(context) {
      const user = await JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      if (user || token) {
        context.commit("login", { user, token });
      }
    },

    async login(context, payLoad) {
      const response = await Auth.login(payLoad);

      const user = response.data.user;
      const token = response.data.token || null;

      context.commit("login", {
        user,
        token,
      });
    },

    async logout(context) {
      await Auth.logout();
      await context.commit("logout");
    },

    async forceLogout(context) {
      context.commit("logout");
    },

    async updateProfile(context, payLoad) {
      const userId = payLoad.user_id;
      const response = await User.updateProfile(payLoad, userId);
      const user = response.data.user;
      context.commit("user", user);
    },
  },
};
