import OfflinePayment from "../../../apis/due/offlinePayment";
export default {
  namespaced: true,

  state() {
    return {
    //   resources: {
    //     data: [],
    //     current_page: 0,
    //     last_page: 1,
    //     total: 0,
    //   },

      //   allResources: [],
      showResource: null,

    //   keys: {
    //     resources: {
    //       localStorageName: "OfflinePayments",
    //     },
    //   },

    //   filters: {
    //     session_id: null,
    //     bill_id: null,
    //     fee_id: null,
    //     standard_id: null,
    //     OfflinePayment_id: null,
    //   },

    //   segment: 'all',
    };
  },

  getters: {
    // resourcesKey(state) {
    //   return state.keys.resources;
    // },

    // resources(state) {
    //   return state.resources;
    // },

    // filters(state) {
    //   return state.filters;
    // },

    // segment(state) {
    //   return state.segment;
    // },

    // allResources(state){
    //   return state.allResources;
    // },

    showResource(state){
      return state.showResource;
    },

    // nextResourcesPage(_, getters) {
    //   const resources = getters.resources;
    //   const current_page = resources.current_page;
    //   const last_page = resources.last_page;
    //   return last_page > current_page ? current_page + 1 : false;
    // },
  },

  mutations: {
    // resources(state, payLoad) {
    //   if (payLoad.refresh) {
    //     state.resources.data = payLoad.response.data;
    //   } else {
    //     state.resources.data.push(...payLoad.response.data);
    //   }
    //   state.resources.current_page = payLoad.response.current_page;
    //   state.resources.last_page = payLoad.response.last_page;
    //   state.resources.total = payLoad.response.total;
    //   localStorage.setItem(payLoad.key, JSON.stringify(state.resources));
    // },

    // filters(state, payLoad) {
    //   state.filters = payLoad;
    // },

    // segment(state, payLoad) {
    //   state.segment = payLoad;
    // },
    // allResources(state, payLoad){
    //   state.allResources = payLoad;
    // }
    showResource(state, payLoad) {
      state.showResource = payLoad;
    },
  },

  actions: {
    // async resources(context, payLoad) {
    //   const refresh = payLoad.refresh ? true : false;
    //   const key = context.getters.resourcesKey;
    //   const filters = context.getters.filters;
    //   const segment = context.getters.segment;
    //   const inLocalStorage = refresh
    //     ? null
    //     : await JSON.parse(localStorage.getItem(key.localStorageName));

    //   if (inLocalStorage) {
    //     context.state.resources = inLocalStorage;
    //   }

    //   const nextResourcesPage = refresh ? 1 : context.getters.nextResourcesPage;

    //   if (nextResourcesPage) {
    //     //  const response = await ImportedApi.index(page)
    //     const response = await OfflinePayment.index(nextResourcesPage, filters, segment);

    //     const dispatch = {
    //       response: response.data,
    //       refresh: refresh,
    //       key: key.localStorageName,
    //     };
    //     context.commit("resources", dispatch);
    //   }
    // },

    // async filters(context, payLoad) {
    //   await context.commit("filters", payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async segment(context, payLoad) {
    //   await context.commit("segment", payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async download(_, payLoad) {
    //   const response = await OfflinePayment.download(payLoad);
    //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //   var fileLink = document.createElement("a");
    //   fileLink.href = fileURL;
    //   fileLink.setAttribute("download", `fee_OfflinePayment_${payLoad}.pdf`);
    //   document.body.appendChild(fileLink);
    //   fileLink.click();
    // },

    // async createResource(context, payLoad) {
    //   // const response = await ImportedApi.create(resource_id)
    //   await OfflinePayment.create(payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    async updateResource(_, payLoad) {
      // const response = await ImportedApi.update(resource_id, data)
      const data = payLoad
      await OfflinePayment.update(payLoad.id, data);
      // context.dispatch("resources", { refresh: true });
    },

    // async deleteResource(context, payLoad) {
    //   // const response = await ImportedApi.delete(resource_id)
    //   await OfflinePayment.delete(payLoad);
    //   context.dispatch("resources", { refresh: true });
    // },

    // async allResources(context){
    //   // All resources without pagination
    //   const allResources = await OfflinePayment.all();
    //   context.commit("allResources", allResources.data)
    // }
    async showResource(context, payLoad) {
      const showResource = await OfflinePayment.show(payLoad.resourceId);
      context.commit("showResource", showResource.data);
    },
  },
};
