import baseApi from "./api.js";
import basePwa from "./pwa.js";

// import { getPlatforms } from "@ionic/vue";
// const platforms = getPlatforms();
// const isApp = platforms.includes("hybrid");

const isApp = true

const Api = isApp ? baseApi : basePwa;

export default Api;
