import Api from '../base/index.js'

export default{
    async index(page=1, data){
        return Api.get(`subject`, {
            params : {
                page: page,
                subject_group_id: data.subject_group_id,
                standard_id: data.standard_id,
            }
        } )
    },

    async all(){
        return Api.get(`subject-all`)
    },

    async show(id){
        return Api.get(`subject/${id}`)
    },

    async create(data){
        return Api.post(`subject`, data)
    },

    async update(id, data){
        return Api.put(`subject/${id}`, data)
    },
    
    async delete(id){
        return Api.delete(`subject/${id}`)
    }
}