import Api from '../base/index.js'

export default{
    async index(page=1, id){
        return Api.get(`subject-teacher`, {
            params : {
                page: page,
                subject_id: id,
            }
        } )
    },

    // async all(){
    //     return Api.get(`subject-teacher-all`)
    // },

    async create(data){
        return Api.post(`subject-teacher`, data)
    },

    // async update(id, data){
    //     return Api.put(`subject-teacher/${id}`, data)
    // },
    
    async delete(id){
        return Api.delete(`subject-teacher/${id}`)
    }
}