import Api from '../base/index.js'

export default{
    async index(page=1){
        return Api.get(`session?page=${page}`)
    },

    async all(){
        return Api.get(`session-all`)
    },

    async create(data){
        return Api.post(`session`, data)
    },

    async update(id, data){
        return Api.put(`session/${id}`, data)
    },
    async delete(id){
        return Api.delete(`session/${id}`)
    }
}