import Api from "../base/index.js";

export default {
  async index(page = 1) {
    return Api.get(`bill?page=${page}`);
  },

  async all() {
    return Api.get(`bill-all`);
  },

  async show(id) {
    return Api.get(`bill/${id}`);
  },

  async create(data) {
    return Api.post(`bill`, data);
  },
};
