import Bill from "../../../apis/due/bill.js";
export default {
  namespaced: true,

  state() {
    return {
      resources: {
        data: {},
        current_page: 0,
        last_page: 1,
        total: 0,
      },

      keys: {
        resources: {
          localStorageName: "billFee",
          paginatedResourceName: "billFees",
          dataResourceName: "bill",
        },
      },
    };
  },

  getters: {
    resourcesKey(state) {
      return state.keys.resources;
    },

    paginatedResourceName(_, getters) {
      return getters.resourcesKey.paginatedResourceName;
    },

    dataResourceName(_, getters) {
      return getters.resourcesKey.dataResourceName;
    },

    title(_, getters) {
      const dataResourceName = getters.dataResourceName;

      const dataResource = getters.resources.data[dataResourceName];

      const title = dataResource.name + ' ' + dataResource.session.name ;

      return title;
    },

    resources(state) {
      return state.resources;
    },

    nextResourcesPage(_, getters) {
      const resources = getters.resources;
      const current_page = resources.current_page;
      const last_page = resources.last_page;
      return last_page > current_page ? current_page + 1 : false;
    },
  },

  mutations: {
    resources(state, payLoad) {
      if (payLoad.refresh) {
        state.resources.data = payLoad.response;
      } else {
        for (let key in payLoad.response) {
          if (key != payLoad.paginatedResourceName) {
            state.resources.data[key] = payLoad.response[key];
          }
        }
        state.resources.data[payLoad.paginatedResourceName].data.push(
          ...payLoad.response[payLoad.paginatedResourceName].data
        );
      }

      state.resources.current_page =
        payLoad.response[payLoad.paginatedResourceName].current_page;

      state.resources.last_page =
        payLoad.response[payLoad.paginatedResourceName].last_page;

      state.resources.total =
        payLoad.response[payLoad.paginatedResourceName].total;

      localStorage.setItem(
        payLoad.localStorageKey,
        JSON.stringify(state.resources)
      );
    },
  },

  actions: {
    async resources(context, payLoad) {
      const refresh = payLoad.refresh ? true : false;
      const resourceId = payLoad.resourceId;

      const paginatedResourceName = context.getters.paginatedResourceName;

      const key = context.getters.resourcesKey;

      const inLocalStorage = refresh
        ? null
        : await JSON.parse(
            localStorage.getItem(key.localStorageName + "-id:" + resourceId)
          );

      if (inLocalStorage) {
        context.state.resources = inLocalStorage;
      }

      const nextResourcesPage =
        refresh || !inLocalStorage ? 1 : context.getters.nextResourcesPage;

      if (nextResourcesPage) {
        const response = await Bill.show(resourceId, nextResourcesPage);

        const dispatch = {
          response: response.data,
          refresh: refresh || !inLocalStorage ? true : false,
          paginatedResourceName: paginatedResourceName,
          localStorageKey: key.localStorageName + "-id:" + resourceId,
        };
        context.commit("resources", dispatch);
      }
    },

    // async createResource(context, payLoad) {
    //   // const response = await ImportedApi.create(resource_id)
    //   await Classroom.create(payLoad);
    //   context.dispatch("resources", { refresh: true, resourceId:payLoad.section_standard_id});
    // },

    // async updateResource(context, payLoad) {
    //   // const response = await ImportedApi.update(resource_id, data)
    //   const data = { roll_no: payLoad.roll_no };
    //   await Classroom.updateStudent(payLoad.id, data);
    //   context.dispatch("resources", { refresh: true, resourceId:payLoad.resourceId });
    // },

    // async deleteResource(context, payLoad) {
    //   // const response = await ImportedApi.delete(resource_id)
    //   const id = payLoad.id
    //   const reloadResourceId = payLoad.reloadResourceId
    //   await Classroom.deleteStudent(id);
    //   context.dispatch("resources", { refresh: true, resourceId:reloadResourceId });
    // },
  },
};
